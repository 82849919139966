import React from "react";

import { SeoComponent } from "../components/SeoComponent";
import StatisticsCard from "../components/StatisticsCard";
import { useAllStats } from "../hooks/useAllStats";

const TeamsStatistics = () => {
  const stats = useAllStats();

  return (
    <div className="flex flex-col w-full">
      <h1 className="text-pink-500 font-bold text-center lg:mt-0 mt-4">
        Süper Lig Takım İstatistikleri
      </h1>
      <div className="flex w-full justify-center dark:text-white dark:bg-gray-900">
        <div className="flex h-full items-center w-8/12 lg:w-full justify-center flex-col lg:flex-row lg:flex-wrap">
          {stats[0].statistics ? (
            stats.map((stat) => {
              return (
                <StatisticsCard
                  key={stat.name}
                  statistics={stat.statistics}
                  label={stat.label}
                  textToAppend={stat.textToAppend}
                  slug={stat.slug}
                  className="lg:w-72 h-80"
                />
              );
            })
          ) : (
            <div className="flex justify-center items-center w-full h-full">
              Yeni sezon istatistikleri ilk hafta bitince...
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default TeamsStatistics;

export const Head = () => (
  <SeoComponent
    title="Süper Lig Takım İstatistikleri"
    description="Türkiye Futbol Süper Ligi 2024-25 Sezonu Takım İstatistikleri: xg, goller, şutlar, kartlar ve fazlası"
    keywords="Futbol, Maçlar, Maclar, Spor, Galatasaray, Fenerbahçe, Beşiktaş, Trabzonspor, Süperlig, Superlig, ensuperlig, ensüperlig, lig istatistikleri, xg, goller, şutlar, kartlar"
  />
);
