import { useStaticQuery, graphql } from "gatsby";
import { statisticsCardNameMap } from "../util/statisticsNameMap";

function sortStatistics(statistics, isAscending) {
  return statistics.sort((a, b) => {
    if (!a || !a.val) {
      return isAscending ? -1 : 1;
    }

    if (!b || !b.val) {
      return isAscending ? 1 : -1;
    }

    return isAscending ? a.val - b.val : b.val - a.val;
  });
}

export const useAllStats = () => {
  const { statisticsJson, allStatNamesJson } = useStaticQuery(graphql`
    query {
      statisticsJson {
        away {
          avgXg {
            team
            val
          }
          avgBallPossession {
            team
            val
          }
          blockedShots {
            team
            val
          }
          cornerKicks {
            team
            val
          }
          fouls {
            team
            val
          }
          ftAgainst {
            team
            val
          }
          ftFor {
            team
            val
          }
          goalkeeperSaves {
            team
            val
          }
          htAgainst {
            team
            val
          }
          htFor {
            team
            val
          }
          offsides {
            team
            val
          }
          avgPassAccuracy {
            team
            val
          }
          passesAccurate {
            team
            val
          }
          redCards {
            team
            val
          }
          shotsInsideBox {
            team
            val
          }
          shotsOffGoal {
            team
            val
          }
          shotsOutsideBox {
            val
            team
          }
          shotsOnGoal {
            team
            val
          }
          totalMatches {
            team
            val
          }
          totalPasses {
            team
            val
          }
          totalShots {
            team
            val
          }
          yellowCards {
            team
            val
          }
          xgPerGoal {
            team
            val
          }
          xgPerShot {
            team
            val
          }
          goalPerShotsOnTarget {
            team
            val
          }
          savePercentage {
            team
            val
          }
          blockedShotsRatio {
            team
            val
          }
          onTargetShotsRatio {
            team
            val
          }
          goalsPerShot {
            team
            val
          }
        }
        home {
          avgXg {
            team
            val
          }
          avgBallPossession {
            team
            val
          }
          blockedShots {
            team
            val
          }
          cornerKicks {
            team
            val
          }
          fouls {
            team
            val
          }
          ftAgainst {
            team
            val
          }
          ftFor {
            team
            val
          }
          goalkeeperSaves {
            team
            val
          }
          htAgainst {
            team
            val
          }
          htFor {
            team
            val
          }
          offsides {
            team
            val
          }
          avgPassAccuracy {
            team
            val
          }
          passesAccurate {
            team
            val
          }
          redCards {
            team
            val
          }
          shotsInsideBox {
            team
            val
          }
          shotsOffGoal {
            team
            val
          }
          shotsOutsideBox {
            val
            team
          }
          shotsOnGoal {
            team
            val
          }
          totalMatches {
            team
            val
          }
          totalPasses {
            team
            val
          }
          totalShots {
            team
            val
          }
          yellowCards {
            team
            val
          }
          xgPerGoal {
            team
            val
          }
          xgPerShot {
            team
            val
          }
          goalPerShotsOnTarget {
            team
            val
          }
          savePercentage {
            team
            val
          }
          blockedShotsRatio {
            team
            val
          }
          onTargetShotsRatio {
            team
            val
          }
          goalsPerShot {
            team
            val
          }
        }
        total {
          avgXg {
            team
            val
          }
          avgBallPossession {
            team
            val
          }
          blockedShots {
            team
            val
          }
          cornerKicks {
            team
            val
          }
          fouls {
            team
            val
          }
          ftAgainst {
            team
            val
          }
          ftFor {
            team
            val
          }
          goalkeeperSaves {
            team
            val
          }
          htAgainst {
            team
            val
          }
          htFor {
            team
            val
          }
          offsides {
            team
            val
          }
          avgPassAccuracy {
            team
            val
          }
          passesAccurate {
            team
            val
          }
          redCards {
            team
            val
          }
          shotsInsideBox {
            team
            val
          }
          shotsOffGoal {
            team
            val
          }
          shotsOutsideBox {
            val
            team
          }
          shotsOnGoal {
            team
            val
          }
          totalMatches {
            team
            val
          }
          totalPasses {
            team
            val
          }
          totalShots {
            team
            val
          }
          yellowCards {
            team
            val
          }
          xgPerGoal {
            team
            val
          }
          xgPerShot {
            team
            val
          }
          goalPerShotsOnTarget {
            team
            val
          }
          savePercentage {
            team
            val
          }
          blockedShotsRatio {
            team
            val
          }
          onTargetShotsRatio {
            team
            val
          }
          goalsPerShot {
            team
            val
          }
        }
      }
      allStatNamesJson(
        sort: { fields: rank }
        filter: { visible: { eq: true } }
      ) {
        nodes {
          slug
          order
          name
        }
      }
    }
  `);

  return allStatNamesJson.nodes.map((stat) => {
    const statistics = statisticsJson.total
      ? sortStatistics(statisticsJson.total[stat.name], stat.order === "asc")
      : null;
    return {
      ...stat,
      statistics,
      label: statisticsCardNameMap[stat.name].label,
      textToAppend: statisticsCardNameMap[stat.name].textToAppend,
    };
  });
};
