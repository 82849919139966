import React from "react";
import { Link } from "gatsby";

import { logosForFullName } from "../util/logos";
import { slugifgyTeamName } from "../util/urlUtil";

export const SingleTeam = ({
  rank,
  name,
  value,
  statisticsName,
  textToAppend,
  big,
  teamName
}) => {
  const slug = slugifgyTeamName(teamName);
  const bgClass = `bg-${slug}-1`;
  const textClass = `text-${slug}-2`;

  return (
    <div
      className={`${big ? bgClass: 'black'} w-full ${big ? textClass : ''} flex relative ${big ? `items-end` : `items-center`} ${
        big ? `h-52` : `h-10`
      } border border-solid py-3`}
    >
      {big && (
        <div className="w-full font-bold flex text-sm xl:text-base z-10 absolute top-4 ml-2">
          {statisticsName}
        </div>
      )}
      {big && (
        <div className="absolute flex w-full justify-center top-16">
          <img width="70px" height="70px" src={logosForFullName[teamName]} alt={teamName} />
        </div>
      )}
      <div className={`${big ? 'font-bold ' : ''} text-sm w-8 ml-4`}>{rank}</div>
      <div className={`${big ? 'font-bold ' : ''} text-sm basis-56 lg:w-40 mr-1`}>{name}</div>
      <div className={`flex flex-grow ${big ? `h-10` : `h-5`}items-end justify-end`}>
        {!big && (
          <div className="w-6">
            <img width="20px" height="20px" src={logosForFullName[teamName]} alt={teamName} />
          </div>
        )}
        <div className={`${big? `w-24` : `w-16`} pr-3 justify-end ${big ? `text-2xl` : `text-sm`} flex font-bold`}>
          {textToAppend}
          {value}
        </div>
      </div>
    </div>
  );
};

const StatisticsCard = ({ statistics, label, textToAppend, slug, className="", showLink=true }) => {
  return (
    <div className={`flex flex-col border border-solid border w-full m-4 bg-gray-50 dark:text-white dark:bg-gray-900 ${className}`}>
      <SingleTeam
        statisticsName={label}
        big={true}
        rank={1}
        name={statistics[0].team}
        value={statistics[0].val}
        textToAppend={textToAppend}
        teamName={statistics[0].team}
      />
      <SingleTeam
        rank={2}
        name={statistics[1].team}
        value={statistics[1].val}
        textToAppend={textToAppend}
        teamName={statistics[1].team}
      />
      <SingleTeam
        rank={3}
        name={statistics[2].team}
        value={statistics[2].val}
        textToAppend={textToAppend}
        teamName={statistics[2].team}
      />
      {showLink &&<Link
        className="flex justify-center items-center text-sm h-10 w-full border border-solid"
        to={slug}
      >
        Tüm listeyi göster
      </Link>}
    </div>
  );
};

export default StatisticsCard;
